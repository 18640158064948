import { saveAs } from "file-saver";
import { getStorage, ref, getBlob } from "firebase/storage";
import JSZip from "jszip";

export const downloadImagesAsZip = async ({ filename, images }) => {
    const zip = new JSZip();
    await Promise.all(addImagesToZip(images, zip));
    const content = await zip.generateAsync({ type: "blob" });
    return saveAs(content, filename);
};

function addImagesToZip(images, zip) {
    console.log("xxx-images", images);
    return images.map(async image => {
        try {
            const storage = getStorage();
            const fileRef = ref(storage, `images/${image.id}`);
            const blob = await getBlob(fileRef);
            const filename = image.folder
                ? `${image.folder}/${image.name}`
                : image.name;
            zip.file(filename, blob, { binary: true });
        } catch (error) {
            console.error(`Failed to add image ${image.id} to zip:`, error);
        }
    });
}

export function getFileExtension(filename) {
    const match = filename.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
    return match ? match[1] : "";
}

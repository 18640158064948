import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Dialog } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { createTheme, styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "moment/locale/pt-br";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory } from "react-router-dom";

import OrderStatus from "../../../constants/order-status";
import UserRoles from "../../../constants/user-roles";
import { AuthContext } from "../../../contexts/auth.context";
import { useDeleteOrder, useListenOrders, useSaveOrder } from "../../../hooks";
import { getPropByString } from "../../../lib/object-utils";
import { getNewOrder, getOrderStatus } from "../../../services";
import OrderActionsButton from "../../orders/order-form/OrderActionsButton";
import PlanningDialog from "../planning-dialog";

moment.locale("pt-br");

export default function PlanningBoard(props) {
    const [viewMode, setViewMode] = useState(viewModeType.WEEK);
    const [viewIndex, setViewIndex] = useState(0);
    const [fullscreen, setFullscreen] = useState(false);
    const [cards, setCards] = useState([]);
    const [openPlanningDialog, setOpenPlanningDialog] = useState();
    const [order, setOrder] = useState(getNewOrder());
    const history = useHistory();

    const updateOrder = useSaveOrder();
    const deleteOrder = useDeleteOrder();

    const getReferenceDate = () => {
        if (viewIndex === 0) return moment();
        if (viewIndex >= 0) return moment().add(viewIndex, viewMode);
        return moment().subtract(viewIndex * -1, viewMode);
    };

    const getStartAndEndDate = () => {
        let startDate = moment();
        let endDate = moment();

        switch (viewMode) {
            case viewModeType.DAY:
                startDate = getReferenceDate().startOf("day").clone();
                endDate = getReferenceDate().endOf("day").clone();
                break;
            case viewModeType.WEEK:
                startDate = getReferenceDate().startOf("isoWeek").clone();
                endDate = getReferenceDate().endOf("isoWeek").clone();
                break;
            case viewModeType.MONTH:
                startDate = getReferenceDate()
                    .startOf("month")
                    .startOf("isoWeek")
                    .clone();
                endDate = getReferenceDate()
                    .endOf("month")
                    .endOf("isoWeek")
                    .clone();
                break;
            default:
                break;
        }
        return {
            startDate: startDate.valueOf(),
            endDate: endDate.valueOf(),
        };
    };

    const { orders } = useListenOrders({
        period: getStartAndEndDate(),
    });

    useEffect(() => {
        setCards(orders);
    }, [orders]);

    const setCurrentOrder = currentOrder => {
        setOrder(currentOrder);
    };

    const onClosePlanningDialog = () => {
        setOpenPlanningDialog(false);
    };

    const onOpenNewWindowDialog = () => {
        setOpenPlanningDialog(false);
        if (order.id) {
            history.push({
                pathname: `/orders/${order.id}`,
            });
        } else {
            history.push({
                pathname: `/orders/new`,
                state: { order: JSON.parse(JSON.stringify(order)) },
            });
        }
    };

    const getTimeFrame = () => {
        switch (viewMode) {
            case viewModeType.DAY:
                return getReferenceDate().format("LL");

            case viewModeType.WEEK:
                return `${getReferenceDate()
                    .startOf("week")
                    .add(1, "d")
                    .format("DD MMM")}. - ${getReferenceDate()
                    .endOf("week")
                    .add(1)
                    .format("DD MMM")}. ${getReferenceDate().format("Y")}`;

            case viewModeType.MONTH:
                return `${getReferenceDate().format(
                    "MMMM",
                )} / ${getReferenceDate().format("Y")}`;

            default:
                break;
        }
    };

    const getByDate = (date, items) =>
        items.filter(item => moment(item.date).isSame(moment(date), "day"));

    const getColumns = () => {
        const columns = [];
        switch (viewMode) {
            case viewModeType.DAY:
                columns.push(getReferenceDate());
                break;
            case viewModeType.WEEK:
                {
                    const startDay = getReferenceDate()
                        .startOf("isoWeek")
                        .clone();
                    const endDay = getReferenceDate().endOf("isoWeek").clone();
                    const day = startDay.clone().subtract(1, "day");
                    Array.from(
                        Array(endDay.diff(startDay, "days") + 1),
                    ).forEach(() => columns.push(day.add(1, "day").clone()));
                }
                break;
            case viewModeType.MONTH:
                {
                    const startDay = getReferenceDate()
                        .startOf("month")
                        .startOf("isoWeek")
                        .clone();
                    const endDay = getReferenceDate()
                        .endOf("month")
                        .endOf("isoWeek")
                        .clone();
                    const day = startDay.clone().subtract(1, "day");
                    Array.from(
                        Array(endDay.diff(startDay, "days") + 1),
                    ).forEach(() => columns.push(day.add(1, "day").clone()));
                }
                break;
            default:
                break;
        }
        return columns.map(column => column.format("YYYY-MM-DD"));
    };

    const getCardsMap = () => {
        const cardsMap = getColumns().reduce((a, v) => ({ ...a, [v]: [] }), {});
        cards
            .map(card => ({
                ...card,
                column: moment(card.date).format("YYYY-MM-DD"),
            }))
            .forEach(card => {
                if (Object.prototype.hasOwnProperty.call(cardsMap, card.column))
                    cardsMap[card.column] = getByDate(card.date, cards).sort(
                        (a, b) => {
                            return a.index - b.index;
                        },
                    );
            });

        return cardsMap;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const cardMap = Object.fromEntries(
            Object.entries(
                reorderMap(getCardsMap(), result.source, result.destination),
            ).map(([k, v]) => [k, v.map((e, i) => ({ ...e, index: i }))]),
        );

        // update card
        if (result.destination.droppableId !== result.source.droppableId) {
            const cardMapObj =
                cardMap[result.destination.droppableId][
                    result.destination.index
                ];
            cardMapObj.date = moment(
                result.destination.droppableId,
            ).toISOString();

            cardMap[result.destination.droppableId][result.destination.index] =
                cardMapObj;

            updateOrder(cardMapObj);
        }

        setCards([
            ...Object.entries(cardMap)
                .map(([key, value]) => value)
                .flat(),
        ]);
    };

    const renderBoardColumns = (column, items) => {
        return (
            <Column
                key={column}
                id={column}
                active={moment(column).isSame(new Date(), "day")}
                header={moment(column).format("ddd")}
                subHeader={moment(column).format("DD/MM")}
            >
                {items.map((item, index) => (
                    <CardItem
                        key={item.id}
                        card={item}
                        index={index}
                        onView={() => {
                            setOrder({
                                ...cards.find(c => c.id === item.id),
                                edit: false,
                            });
                            setOpenPlanningDialog(true);
                        }}
                        onEdit={() => {
                            setOrder({
                                ...cards.find(c => c.id === item.id),
                                edit: true,
                            });
                            setOpenPlanningDialog(true);
                        }}
                        onDelete={() => {
                            deleteOrder({
                                ...cards.find(c => c.id === item.id),
                            });
                        }}
                    />
                ))}
            </Column>
        );
    };

    const HeaderLeftArea = () => {
        const { hasRole } = useContext(AuthContext);
        const canCreate = hasRole(UserRoles.CREATE_ROADMAP);
        return (
            <Box flex={0} display="flex">
                {canCreate && (
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setOrder(getNewOrder());
                            setOpenPlanningDialog(true);
                        }}
                    >
                        Novo
                    </Button>
                )}
            </Box>
        );
    };

    const HeaderMiddleArea = () => (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
            flexShrink={0}
            flexBasis="auto"
        >
            <IconButton
                aria-label="back"
                onClick={() => {
                    setViewIndex(viewIndex - 1);
                }}
            >
                <ArrowBackIosIcon fontSize="small" />
            </IconButton>
            <Typography variant="subtitle1" component="div">
                {getTimeFrame()}
            </Typography>
            <IconButton
                aria-label="back"
                onClick={() => {
                    setViewIndex(viewIndex + 1);
                }}
            >
                <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
        </Box>
    );

    const HeaderRightArea = () => (
        <Box flex={0} display="flex">
            <ToggleButtonGroup
                color="primary"
                value={viewMode}
                exclusive
                onChange={(event, newViewMode) => {
                    setViewMode(newViewMode);
                    setViewIndex(0);
                }}
                size="small"
            >
                <ToggleButton value={viewModeType.DAY}>Dia</ToggleButton>
                <ToggleButton value={viewModeType.WEEK}>Semana</ToggleButton>
                <ToggleButton value={viewModeType.MONTH}>Mês</ToggleButton>
            </ToggleButtonGroup>
            <Divider
                sx={{ paddingLeft: 1, paddingRight: 1 }}
                orientation="vertical"
            />
            <IconButton
                color="primary"
                aria-label="abir em tela cheia"
                onClick={() => {
                    setFullscreen(!fullscreen);
                }}
            >
                {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
        </Box>
    );

    const PlanningContainer = () => (
        <Board>
            <BoardHeader>
                <HeaderLeftArea />
                <HeaderMiddleArea />
                <HeaderRightArea />
            </BoardHeader>
            <BoardBody>
                <DragDropContext onDragEnd={onDragEnd}>
                    {Object.entries(getCardsMap()).map(([column, items]) =>
                        renderBoardColumns(column, items),
                    )}
                </DragDropContext>
            </BoardBody>
        </Board>
    );

    return (
        <React.Fragment>
            {fullscreen ? (
                <Dialog fullScreen open={true}>
                    <PlanningContainer />
                </Dialog>
            ) : (
                <PlanningContainer />
            )}
            <PlanningDialog
                open={openPlanningDialog}
                onClose={onClosePlanningDialog}
                onOrderChanged={setCurrentOrder}
                onOpenNewWindow={onOpenNewWindowDialog}
                edit={order.edit}
                data={order}
                {...props}
            />
        </React.Fragment>
    );
}

const viewModeType = { DAY: "day", WEEK: "w", MONTH: "M" };

const theme = createTheme();

const Board = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    bgcolor: theme.palette.text.secondary,
    minHeight: "100vh",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
}));

const BoardBody = styled(props => {
    const { ...other } = props;
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={0}
            columns={{ xs: 1, sm: 2, md: 7, lg: 7, xl: 7 }}
            {...other}
        />
    );
})(({ theme }) => ({
    padding: theme.spacing(1),
    minHeight: "80%",
    with: "100%",
    flexGrow: 1,
}));

const BoardHeader = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    with: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 0,
}));

const ColumnItem = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    with: "100%",
    height: "100%",
    minHeight: "250px",
    flex: 1,
}));

const Column = props => {
    return (
        <Grid item key={props.id} xs={1} sm={1} md={1} lg={1} xl={1}>
            <ColumnItem square sx={getItemStyle(props.active)}>
                <Box
                    sx={{
                        ...(!props.active && { bgcolor: grey[50] }),
                    }}
                    ju
                >
                    <Typography align="center" variant="body2">
                        {props.header}
                    </Typography>
                    <Typography
                        align="center"
                        variant="caption"
                        display="block"
                        gutterBottom
                    >
                        {props.subHeader}
                    </Typography>
                </Box>
                <Divider />
                <Droppable droppableId={props.id}>
                    {(provided, snapshot) => (
                        <Box
                            ref={provided.innerRef}
                            sx={getDroppAreaStyle(snapshot.isDraggingOver)}
                        >
                            {props.children}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </ColumnItem>
        </Grid>
    );
};

const getItemStyle = isActive => ({
    ...(isActive && {
        border: "1px solid",
        borderColor: theme.palette.warning.main,
        backgroundColor: orange[50],
    }),
});

const getDroppAreaStyle = isDraggingOver => ({
    ...(isDraggingOver && {
        backgroundColor: blue[50],
    }),
    minHeight: "80%",
});

const getCardStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: grey[200],
    }),

    margin: theme.spacing(1),
    textAlign: "left",
});

const CardBody = styled(props => {
    const { ...other } = props;
    return <Box {...other} />;
})(({ theme, status }) => ({
    borderLeftWidth: theme.spacing(1 / 2),
    borderLeftStyle: "solid",
    borderColor: getPropByString(theme.palette, status.color).main,
    // borderColor: [OrderStatus.RELEASED, OrderStatus.IN_PROGRESS].includes(
    //     status.id,
    // )
    //     ? green[500]
    //     : red[400],
}));

const CardItem = ({ card, index, onEdit, onDelete, onCopy, onView }) => {
    const { hasRole } = useContext(AuthContext);
    const { customer } = card;
    console.log("xxx- card", card);
    return (
        <Draggable
            key={card.id}
            draggableId={card.id}
            index={index}
            isDragDisabled={!hasRole(UserRoles.UPDATE_ROADMAP)}
        >
            {(provided, snapshot) => (
                <Card
                    raised
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getCardStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                    )}
                >
                    <CardBody status={card.status}>
                        <CardContent>
                            <Typography
                                variant="subtitle2"
                                component="div"
                                gutterBottom
                            >
                                {customer.companyName}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                gutterBottom
                            >
                                {card.description}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                component="div"
                                gutterBottom
                            >
                                {card.number}
                            </Typography>

                            {card.assignees.map((assignee, index) => {
                                return (
                                    <Typography
                                        key={index}
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {assignee.name}
                                    </Typography>
                                );
                            })}
                        </CardContent>
                        <CardActions
                            style={{
                                justifyContent: "flex-end",
                                flexWrap: "wrap",
                            }}
                        >
                            <IconButton aria-label="view">
                                <VisibilityIcon
                                    fontSize="small"
                                    onClick={onView}
                                />
                            </IconButton>
                            {hasRole(UserRoles.CREATE_ROADMAP) && (
                                <IconButton aria-label="copy" onClick={onCopy}>
                                    <CopyIcon fontSize="small" />
                                </IconButton>
                            )}
                            {hasRole(UserRoles.UPDATE_ROADMAP) && (
                                <IconButton aria-label="edit" onClick={onEdit}>
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                            <OrderActionsButton
                                order={card}
                                type="iconButton"
                            />
                        </CardActions>
                    </CardBody>
                </Card>
            )}
        </Draggable>
    );
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const reorderMap = (listMap, source, destination) => {
    const current = [...listMap[source.droppableId]];
    const next = [...listMap[destination.droppableId]];
    const target = current[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
        const reordered = reorder(current, source.index, destination.index);
        const result = {
            ...listMap,
            [source.droppableId]: reordered,
        };
        return result;
    }

    // moving to different list

    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    const result = {
        ...listMap,
        [source.droppableId]: current,
        [destination.droppableId]: next,
    };

    return result;
};

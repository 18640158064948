import React, { useContext, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Breadcrumbs,
    Chip,
    Collapse,
    createTheme,
    Divider,
    IconButton,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import classNames from "classnames";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import "moment/locale/pt-br";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Store as notifications } from "react-notifications-component";
import { PhotoAlbum } from "react-photo-album";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { v4 as uuidv4 } from "uuid";

import notificationOpts from "../../../constants/notification-options";
import notificationType from "../../../constants/notification-types";
import {
    CLIMATIC_CONDITIONS,
    SAMPLING_TYPES,
    WELL_DIAMETER,
} from "../../../constants/order-sample";
import OrderStatus from "../../../constants/order-status";
import UserRoles from "../../../constants/user-roles";
import { AuthContext } from "../../../contexts";
import {
    downloadImagesAsZip,
    getFileExtension,
} from "../../../lib/image-downloader";
import sortBy from "../../../lib/sort-by";
import {
    orderTaskSampleStatusColor,
    orderTaskSampleStatusName,
    orderTaskStatusLabelStyle,
    orderTaskStatusName,
} from "../../../lib/status.utils";
import { booleanToStr } from "../../../lib/string-utils";
import { updateDocument } from "../../../services";
import DetailView from "../../commons/detail-view";
import DynamicInlineEditor from "../../commons/dynamic-inline-editor/DynamicInLineEditor";
import FormSection from "../../commons/form-section/FormSection";
import ScrollToTopFab from "../../commons/scroll-to-top-fab/ScrollToTopFab";

const theme = createTheme();

export default function OrderTaskF037({ order, task }) {
    return (
        <DetailView
            headerTitle={<Header order={order} task={task} />}
            headerActions={<HeaderActions order={order} task={task} />}
            subHeader1={<SubHeader1 order={order} task={task} />}
            content={<Content order={order} task={task} />}
        />
    );
}

const Header = ({ order, task }) => {
    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <NavLink to="/orders">
                    <Typography color="text.secondary">
                        Ordens de Serviço
                    </Typography>
                </NavLink>
                <NavLink to={`/orders/${order.id}`}>
                    <Typography color="text.secondary">
                        {order.number}
                    </Typography>
                </NavLink>
                <Typography color="text.primary">{task.description}</Typography>
            </Breadcrumbs>
        </Box>
    );
};

const HeaderActions = ({ order, task }) => {
    const [isLoading, setLoading] = useState(false);
    return (
        <Stack direction="row" spacing={1}>
            <LoadingButton
                size="small"
                variant="contained"
                startIcon={<DownloadIcon />}
                loading={isLoading}
                onClick={() => {
                    setLoading(true);
                    downloadAllImages({ order, task }).then(() =>
                        setLoading(false),
                    );
                }}
            >
                Download Imagens
            </LoadingButton>
        </Stack>
    );
};

const SubHeader1 = ({ task }) => {
    const { status } = task;
    return (
        <Box>
            <span>
                <span
                    style={{ fontSize: 12 }}
                    className={classNames(
                        "label",
                        orderTaskStatusLabelStyle(status),
                    )}
                >
                    {orderTaskStatusName(status)}
                </span>
            </span>
        </Box>
    );
};

const Content = ({ order, task }) => {
    const { hasRole } = useContext(AuthContext);
    const readonly = !(
        hasRole(UserRoles.PERFORM_ORDER_VERIFICATION) &&
        order.status.id === OrderStatus.VERIFICATION
    );
    const dataList = task.getData();
    const sampleDataList = dataList
        .map(data => getSampleData({ data, order }))
        .sort(sortBy("name"));

    if (sampleDataList.length === 0) {
        return (
            <Box mt={2}>
                <Typography variant="h6">
                    Não há dados de amostragem para esta tarefa
                </Typography>
            </Box>
        );
    }

    return (
        <Box mt={2}>
            <TextField
                fullWidth
                multiline
                minRows={4}
                id="task-note"
                value={task.note}
                label="Observações"
                variant="standard"
            />
            <Box mt={2}>
                <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                >
                    {sampleDataList.map(d => (
                        <HashLink key={d.name} to={`#${d.name}`}>
                            <Typography color="primary" variant="h6">
                                {d.name}
                            </Typography>
                        </HashLink>
                    ))}
                </Stack>
            </Box>
            {sampleDataList.map(d => (
                <div key={d.name} id={d.name}>
                    <SampleData
                        sampleData={d}
                        order={order}
                        readonly={readonly}
                    />
                </div>
            ))}
        </Box>
    );
};

const SampleData = ({ sampleData, order, readonly }) => {
    const [isLoading, setIsLoading] = useState({});
    const [openData, setOpenData] = useState(true);
    const [openParameters, setOpenParameters] = useState(true);
    const [openImagens, setOpenImagens] = useState(true);

    const populateLoadingFields = value => {
        const loadingFields = {};
        Object.keys(value).forEach(k => {
            if (k.includes("equipment")) {
                loadingFields.equipment = {};
                Object.keys(value[k]).forEach(v => {
                    loadingFields[`equipment.${v}`] = true;
                });
            } else {
                if (k.includes("stabilizationParameters")) {
                    Object.keys(value[k]).forEach(v => {
                        const item = value[k];
                        loadingFields[
                            `stabilizationParameters.${item.id}.${v}`
                        ] = true;
                    });
                } else {
                    loadingFields[k] = true;
                }
            }
        });
        setIsLoading({ ...loadingFields });
    };

    const getLoadingStatus = field => {
        return isLoading[field] ? isLoading[field] : false;
    };

    const saveData = async value => {
        populateLoadingFields(value);
        const data = sampleData.data;
        const sampleDataKey = `tasks.${data.taskId}.data.${data.id}`;

        let updateData = value;
        if (Object.keys(value).includes("equipment")) {
            updateData = {
                equipment: {
                    ...data.equipment,
                    ...value.equipment,
                },
            };
        }

        if (Object.keys(value).includes("stabilizationParameters")) {
            const stabilizationParameters = data.stabilizationParameters
                ? data.stabilizationParameters
                : {};
            if (value.stabilizationParameters.delete) {
                delete stabilizationParameters[
                    value.stabilizationParameters.id
                ];
                updateData = {
                    stabilizationParameters: { ...stabilizationParameters },
                    ...getStartEndTime(stabilizationParameters),
                };
            } else {
                updateData = {
                    stabilizationParameters: {
                        ...stabilizationParameters,
                        [value.stabilizationParameters.id]: {
                            ...stabilizationParameters[
                                value.stabilizationParameters.id
                            ],
                            ...value.stabilizationParameters,
                        },
                    },
                    ...getStartEndTime(stabilizationParameters),
                };
            }
        }

        updateDocument(`orders/${order.id}`, {
            [sampleDataKey]: {
                ...data,
                ...updateData,
            },
        }).then(() => {
            setIsLoading({});
        });
    };

    const PMField = () => <SampleDataItem name="PM" value={sampleData.name} />;

    const StatusField = () => (
        <SampleDataItem
            name="Status"
            value={
                <Chip
                    size="small"
                    label={orderTaskSampleStatusName(sampleData.status)}
                    color={orderTaskSampleStatusColor(sampleData.status)}
                />
            }
        />
    );

    const SampleTypeField = () => (
        <SampleDataItem
            name="Tipo de Amostragem"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.samplingType}
                    loading={getLoadingStatus("samplingType")}
                    readonly={readonly}
                    initialValue={sampleData.data.samplingType}
                    renderInput={(value, onChange) => {
                        return (
                            <Autocomplete
                                id="samplingType"
                                options={SAMPLING_TYPES}
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={sampleData.data.samplingType || null}
                                onChange={(event, value) => onChange(value)}
                                disabled={getLoadingStatus("samplingType")}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        margin="normal"
                                    />
                                )}
                                size="small"
                            />
                        );
                    }}
                    onSave={value => saveData({ samplingType: value })}
                />
            }
        />
    );

    const ClimatictConditionField = () => (
        <SampleDataItem
            name="Condições climáticas locais"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.climatictCondition}
                    loading={getLoadingStatus("climatictCondition")}
                    readonly={readonly}
                    initialValue={sampleData.data.climatictCondition}
                    renderInput={(value, onChange) => {
                        return (
                            <Autocomplete
                                id="climatictCondition"
                                options={CLIMATIC_CONDITIONS}
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={
                                    sampleData.data.climatictCondition || null
                                }
                                onChange={(event, value) => onChange(value)}
                                disabled={getLoadingStatus(
                                    "climatictCondition",
                                )}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        margin="normal"
                                    />
                                )}
                                size="small"
                            />
                        );
                    }}
                    onSave={value => saveData({ climatictCondition: value })}
                />
            }
        />
    );

    const TemperatureField = () => (
        <SampleDataItem
            name="Temperatura (ºC)"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.temperature}
                    loading={getLoadingStatus("temperature")}
                    readonly={readonly}
                    initialValue={sampleData.data.temperature}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            label="Temperatura (ºC)"
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ temperature: value })}
                />
            }
        />
    );

    const OilVisibleField = () => (
        <SampleDataItem
            name="Óleo visível"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.oilVisible}
                    loading={getLoadingStatus("oilVisible")}
                    readonly={readonly}
                    initialValue={sampleData.data.oilVisible}
                    renderInput={(value, onChange) => (
                        <SwitchTwo
                            op1="Não"
                            op2="Sim"
                            checked={value}
                            onChange={e => onChange(e.target.checked)}
                        />
                    )}
                    onSave={value => saveData({ oilVisible: value })}
                />
            }
        />
    );

    const OdorField = () => (
        <SampleDataItem
            name="Odor"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.odor}
                    loading={getLoadingStatus("odor")}
                    readonly={readonly}
                    initialValue={sampleData.data.odor}
                    renderInput={(value, onChange) => (
                        <SwitchTwo
                            op1="Não"
                            op2="Sim"
                            checked={value}
                            onChange={e => onChange(e.target.checked)}
                        />
                    )}
                    onSave={value => saveData({ odor: value })}
                />
            }
        />
    );

    const DamagedField = () => (
        <SampleDataItem
            name="Danificado"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.damaged}
                    loading={getLoadingStatus("damaged")}
                    readonly={readonly}
                    initialValue={sampleData.data.damaged}
                    renderInput={(value, onChange) => (
                        <SwitchTwo
                            op1="Não"
                            op2="Sim"
                            checked={value}
                            onChange={e => onChange(e.target.checked)}
                        />
                    )}
                    onSave={value => saveData({ damaged: value })}
                />
            }
        />
    );

    const CapField = () => (
        <SampleDataItem
            name="CAP"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.cap}
                    loading={getLoadingStatus("cap")}
                    readonly={readonly}
                    initialValue={sampleData.data.cap}
                    renderInput={(value, onChange) => (
                        <SwitchTwo
                            op1="Não"
                            op2="Sim"
                            checked={value}
                            onChange={e => onChange(e.target.checked)}
                        />
                    )}
                    onSave={value => saveData({ cap: value })}
                />
            }
        />
    );

    const PreservationField = () => (
        <SampleDataItem
            name="Preservação"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.preservation}
                    loading={getLoadingStatus("preservation")}
                    readonly={readonly}
                    initialValue={sampleData.data.preservation}
                    renderInput={(value, onChange) => (
                        <SwitchTwo
                            op1="Não"
                            op2="Sim"
                            checked={value}
                            onChange={e => onChange(e.target.checked)}
                        />
                    )}
                    onSave={value => saveData({ preservation: value })}
                />
            }
        />
    );

    const DepletedSystemVolume = () => (
        <SampleDataItem
            name="Volume esgotado do sistema (mL)"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.depletedSystemVolume}
                    loading={getLoadingStatus("depletedSystemVolume")}
                    readonly={readonly}
                    initialValue={sampleData.data.depletedSystemVolume}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ depletedSystemVolume: value })}
                />
            }
        />
    );

    const TotalDepletedVolumeField = () => (
        <SampleDataItem
            name="Volume total esgotado (mL)"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.totalDepletedVolume}
                    loading={getLoadingStatus("totalDepletedVolume")}
                    readonly={readonly}
                    initialValue={sampleData.data.totalDepletedVolume}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ totalDepletedVolume: value })}
                />
            }
        />
    );

    const WellDiameterField = () => (
        <SampleDataItem
            name="Diâmetro do poço"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.wellDiameter}
                    loading={getLoadingStatus("wellDiameter")}
                    readonly={readonly}
                    initialValue={sampleData.data.wellDiameter}
                    renderInput={(value, onChange) => {
                        return (
                            <Autocomplete
                                id="wellDiameter"
                                options={WELL_DIAMETER}
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={sampleData.data.wellDiameter || null}
                                onChange={(event, value) => onChange(value)}
                                disabled={getLoadingStatus("wellDiameter")}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        margin="normal"
                                    />
                                )}
                                size="small"
                            />
                        );
                    }}
                    onSave={value => saveData({ wellDiameter: value })}
                />
            }
        />
    );

    const NAStaticField = () => (
        <SampleDataItem
            name="NA Estático (m)"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.NAStatic}
                    loading={getLoadingStatus("NAStatic")}
                    readonly={readonly}
                    initialValue={sampleData.data.NAStatic}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ NAStatic: value })}
                />
            }
        />
    );

    const DepthField = () => (
        <SampleDataItem
            name="Profundidade (m)"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.depth}
                    loading={getLoadingStatus("depth")}
                    readonly={readonly}
                    initialValue={sampleData.data.depth}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ depth: value })}
                />
            }
        />
    );

    const PumpPositionField = () => (
        <SampleDataItem
            name="Posicionamento da Bomba"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.pumpPosition}
                    loading={getLoadingStatus("pumpPosition")}
                    readonly={readonly}
                    initialValue={sampleData.data.pumpPosition}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ pumpPosition: value })}
                />
            }
        />
    );

    const ParametersField = () => (
        <SampleDataItem
            name="Parâmetros de Análise"
            value={sampleData.parameters}
        />
    );

    const AmostbvField = () => (
        <SampleDataItem
            name="AMOSTBV"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.amostbv}
                    loading={getLoadingStatus("equipment.amostbv")}
                    readonly={readonly}
                    initialValue={sampleData.amostbv}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value =>
                        saveData({
                            equipment: {
                                amostbv: value,
                            },
                        })
                    }
                />
            }
        />
    );

    const MednaField = () => (
        <SampleDataItem
            name="MEDNA"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.medna}
                    loading={getLoadingStatus("equipment.medna")}
                    readonly={readonly}
                    initialValue={sampleData.medna}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value =>
                        saveData({
                            equipment: {
                                medna: value,
                            },
                        })
                    }
                />
            }
        />
    );

    const MedintField = () => (
        <SampleDataItem
            name="MEDINT"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.medint}
                    loading={getLoadingStatus("equipment.medint")}
                    readonly={readonly}
                    initialValue={sampleData.medint}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value =>
                        saveData({
                            equipment: {
                                medint: value,
                            },
                        })
                    }
                />
            }
        />
    );

    const TurbField = () => (
        <SampleDataItem
            name="TURB"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.turb}
                    loading={getLoadingStatus("equipment.turb")}
                    readonly={readonly}
                    initialValue={sampleData.turb}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value =>
                        saveData({
                            equipment: {
                                turb: value,
                            },
                        })
                    }
                />
            }
        />
    );

    const TermcampField = () => (
        <SampleDataItem
            name="TERM CAMP"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.termcamp}
                    loading={getLoadingStatus("equipment.termcamp")}
                    readonly={readonly}
                    initialValue={sampleData.termcamp}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value =>
                        saveData({
                            equipment: {
                                termcamp: value,
                            },
                        })
                    }
                />
            }
        />
    );

    const NoteField = () => (
        <SampleDataItem
            name="Observações"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.note}
                    loading={getLoadingStatus("note")}
                    readonly={readonly}
                    initialValue={sampleData.note}
                    renderInput={(value, onChange) => (
                        <TextField
                            value={value}
                            onChange={e => onChange(e.target.value)}
                            type="number"
                            variant="standard"
                            size="small"
                            margin="normal"
                            fullWidth
                        />
                    )}
                    onSave={value => saveData({ note: value })}
                />
            }
        />
    );

    const TaskResponsibleField = () => (
        <SampleDataItem
            name="Responsável pela operação"
            value={
                <DynamicInlineEditor
                    displayComponent={sampleData.taskResponsible}
                    loading={getLoadingStatus("taskResponsible")}
                    readonly={readonly}
                    initialValue={sampleData.data.taskResponsible}
                    renderInput={(value, onChange) => {
                        return (
                            <Autocomplete
                                id="taskResponsible"
                                options={order.assignees}
                                getOptionLabel={option => option.name}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                value={sampleData.data.taskResponsible || null}
                                onChange={(event, value) => onChange(value)}
                                disabled={getLoadingStatus("taskResponsible")}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        margin="normal"
                                    />
                                )}
                                size="small"
                            />
                        );
                    }}
                    onSave={value => saveData({ taskResponsible: value })}
                />
            }
        />
    );

    const StabilizationParametersTimes = () => (
        <SampleDataItem
            name="Estabilização dos parâmetros de amostragem"
            value={`Início: ${sampleData.stabilizationStartTime} Término: ${sampleData.stabilizationEndTime}`}
        />
    );

    const SampleDataFields = () => (
        <Table size="small" aria-label="sample-data-fields">
            <TableBody>
                <PMField />
                <StatusField />
                <SampleTypeField />
                <ClimatictConditionField />
                <TemperatureField />
                <OilVisibleField />
                <OdorField />
                <DamagedField />
                <CapField />
                <PreservationField />
                <DepletedSystemVolume />
                <TotalDepletedVolumeField />
                <WellDiameterField />
                <NAStaticField />
                <DepthField />
                <PumpPositionField />
                <ParametersField />
                <AmostbvField />
                <MednaField />
                <MedintField />
                <TurbField />
                <TermcampField />
                <NoteField />
                <TaskResponsibleField />
                <StabilizationParametersTimes />
            </TableBody>
        </Table>
    );

    return (
        <FormSection title={sampleData.name}>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Box sx={{ margin: 1 }}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() =>
                                                setOpenData(!openData)
                                            }
                                        >
                                            {openData ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            component="div"
                                        >
                                            Dados
                                        </Typography>
                                    </Stack>
                                    <Collapse
                                        in={openData}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Box mt={2}>
                                            <SampleDataFields />
                                        </Box>
                                    </Collapse>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Box sx={{ margin: 1 }}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() =>
                                                setOpenParameters(
                                                    !openParameters,
                                                )
                                            }
                                        >
                                            {openParameters ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            component="div"
                                        >
                                            Parâmetros de estabilização
                                        </Typography>
                                    </Stack>
                                    <Collapse
                                        in={openParameters}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Box mt={2}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                            >
                                                <StabilizationParameters
                                                    parameters={
                                                        sampleData.stabilizationParameters
                                                    }
                                                    onSave={value => {
                                                        saveData({
                                                            stabilizationParameters:
                                                                {
                                                                    ...value,
                                                                },
                                                        });
                                                    }}
                                                    getLoadingStatus={
                                                        getLoadingStatus
                                                    }
                                                    readonly={readonly}
                                                />
                                            </LocalizationProvider>
                                        </Box>
                                    </Collapse>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                colSpan={2}
                            >
                                <Box sx={{ margin: 1 }}>
                                    <Stack direction="row" spacing={2}>
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() =>
                                                setOpenImagens(!openImagens)
                                            }
                                        >
                                            {openImagens ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </IconButton>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            component="div"
                                        >
                                            Imagens
                                        </Typography>
                                    </Stack>
                                    <Collapse
                                        in={openImagens}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <Box mt={2}>
                                            <TaskImages
                                                order={order}
                                                sampleData={sampleData}
                                                images={sampleData.images}
                                            />
                                        </Box>
                                    </Collapse>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <ScrollToTopFab />
        </FormSection>
    );
};

const StabilizationParameters = ({
    parameters,
    onSave,
    getLoadingStatus,
    readonly,
}) => {
    const confirm = useConfirm(); // Hook to request user confirmation

    const addParameter = () => {
        const newParam = {
            id: uuidv4(),
            time: moment().toISOString(),
            conductivity: "",
            na: "",
            note: "",
            od: "",
            orp: "",
            ph: "",
            temperature: "",
            timeText: "",
            turbidity: "",
        };
        parameters.push(newParam);
        onSave(newParam);
    };

    const removeParameter = parameter => {
        return confirm({
            // Request user confirmation before proceeding
            title: "Confirmar", // Title of the confirmation dialog
            description: `Deseja remover esta leitura??`, // Confirmation message
            confirmationText: "Sim", // Text for the confirmation button
            cancellationText: "Não", // Text for the cancellation button
        })
            .then(() => {
                // If confirmed, execute the action
                parameters.splice(
                    parameters.findIndex(v => v.id === parameter.id),
                    1,
                );
                return onSave({
                    ...parameter,
                    delete: true,
                }).catch(error => {
                    // Log and notify any errors during execution
                    console.error(
                        `Error deleting parameter: ${parameter.id}`,
                        error,
                    );
                    notifications.addNotification({
                        ...notificationOpts,
                        type: notificationType.ERROR,
                        message:
                            "Não foi possível remover esta leitura, tente novamente!",
                    });
                });
            })
            .catch(() => {
                // Catch any errors from the confirmation process
            });
    };

    return (
        <Table size="small" aria-label="stabilization-parameters">
            <TableHead>
                <TableRow>
                    <TableCell align="center">
                        {!readonly && (
                            <IconButton
                                aria-label="add"
                                size="small"
                                onClick={addParameter}
                            >
                                <AddCircleOutlineIcon fontSize="inherit" />
                            </IconButton>
                        )}
                    </TableCell>
                    <TableCell align="center">NBR 15847:2010</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">± 5%</TableCell>
                    <TableCell align="center">± 20 milivolts</TableCell>
                    <TableCell align="center">± 0,2 mg/L</TableCell>
                    <TableCell align="center">± 0,2 unidades</TableCell>
                    <TableCell align="center">± 0,5 ºC</TableCell>
                    <TableCell align="center">-</TableCell>
                    <TableCell align="center">-</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center" />
                    <TableCell align="center">Hora</TableCell>
                    <TableCell>
                        NA
                        <br />
                        (m)
                    </TableCell>
                    <TableCell align="center">
                        Condutividade
                        <br />
                        (µS/cm)
                    </TableCell>
                    <TableCell align="center">
                        ORP
                        <br />
                        (mV)
                    </TableCell>
                    <TableCell align="center">OD</TableCell>
                    <TableCell align="center">pH</TableCell>
                    <TableCell align="center">
                        Temperatura
                        <br />
                        ºC
                    </TableCell>
                    <TableCell align="center">
                        Turbidez
                        <br />
                        NTU
                    </TableCell>
                    <TableCell align="center">Obs.</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {parameters.length ? (
                    parameters
                        .map(param => {
                            return {
                                ...param,
                                loading: {
                                    time: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.time`,
                                    ),
                                    na: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.na`,
                                    ),
                                    conductivity: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.conductivity`,
                                    ),
                                    orp: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.orp`,
                                    ),
                                    od: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.od`,
                                    ),
                                    ph: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.ph`,
                                    ),
                                    temperature: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.temperature`,
                                    ),
                                    turbidity: getLoadingStatus(
                                        `stabilizationParameters.${param.id}.turbidity`,
                                    ),
                                    note: getLoadingStatus(
                                        `stabilizationParameters.${param.note}.turbidity`,
                                    ),
                                },
                            };
                        })
                        .map(param => (
                            <TableRow key={param.id}>
                                <TableCell align="center">
                                    {!readonly && (
                                        <IconButton
                                            aria-label="add"
                                            size="small"
                                            onClick={() =>
                                                removeParameter(param)
                                            }
                                        >
                                            <DeleteOutlineIcon fontSize="inherit" />
                                        </IconButton>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.timeText}
                                        loading={param.loading.time}
                                        readonly={readonly}
                                        initialValue={param.time}
                                        renderInput={(value, onChange) => (
                                            <DateTimePicker
                                                label="Hora (HH:MM)"
                                                value={moment(
                                                    value,
                                                    moment.ISO_8601,
                                                )}
                                                onChange={newValue =>
                                                    onChange(
                                                        newValue.toISOString(),
                                                    )
                                                }
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                    />
                                                )}
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                time: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.na}
                                        loading={param.loading.na}
                                        readonly={readonly}
                                        initialValue={param.na}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                na: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.conductivity}
                                        loading={param.loading.conductivity}
                                        readonly={readonly}
                                        initialValue={param.conductivity}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                conductivity: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.orp}
                                        loading={param.loading.orp}
                                        readonly={readonly}
                                        initialValue={param.orp}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                orp: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.od}
                                        loading={param.loading.od}
                                        readonly={readonly}
                                        initialValue={param.od}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                od: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.ph}
                                        loading={param.loading.ph}
                                        readonly={readonly}
                                        initialValue={param.ph}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                ph: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.temperature}
                                        loading={param.loading.temperature}
                                        readonly={readonly}
                                        initialValue={param.temperature}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                temperature: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.turbidity}
                                        loading={param.loading.turbidity}
                                        readonly={readonly}
                                        initialValue={param.turbidity}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                type="number"
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                turbidity: value,
                                            })
                                        }
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    <DynamicInlineEditor
                                        displayComponent={param.note}
                                        loading={param.loading.note}
                                        readonly={readonly}
                                        initialValue={param.note}
                                        renderInput={(value, onChange) => (
                                            <TextField
                                                value={value}
                                                onChange={e =>
                                                    onChange(e.target.value)
                                                }
                                                variant="standard"
                                                size="small"
                                                margin="normal"
                                                fullWidth
                                            />
                                        )}
                                        onSave={value =>
                                            onSave({
                                                id: param.id,
                                                note: value,
                                            })
                                        }
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                ) : (
                    <TableRow>
                        <TableCell align="center" colSpan={10}>
                            Não há dados para exibição
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};

const SampleDataItem = ({ name, value }) => {
    return (
        <TableRow>
            <TableCell
                align="right"
                sx={{
                    width: "auto",
                    whiteSpace: "nowrap",
                    color: theme.palette.text.disabled,
                }}
            >
                {name}
            </TableCell>
            <TableCell align="left" width="100%">
                {value}
            </TableCell>
        </TableRow>
    );
};

const TaskImages = ({ order, sampleData, images }) => {
    const [isLoading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const photos = images.map(img => {
        return {
            src: img.url,
            height: 74,
            width: 74,
        };
    });

    if (!images || !images.length) {
        return (
            <Box>
                <Typography variant="body1" gutterBottom>
                    Não há imagens para exibição
                </Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
            >
                <LoadingButton
                    size="small"
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    loading={isLoading}
                    onClick={() => {
                        setLoading(true);
                        const filename = `images-${order.number}-F037-${sampleData.name}`;
                        downloadImagesAsZip({ filename, images }).then(() =>
                            setLoading(false),
                        );
                    }}
                >
                    Fazer Download
                </LoadingButton>
            </Stack>
            <PhotoAlbum
                photos={photos}
                layout="masonry"
                targetRowHeight={150}
                onClick={({ index }) => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                }}
            />
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex].url}
                    nextSrc={images[(photoIndex + 1) % images.length].url}
                    prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                            .url
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + images.length - 1) % images.length,
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </Box>
    );
};

function SwitchTwo({ op1, op2, onChange, checked = false }) {
    return (
        <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
        >
            <Typography>{op1}</Typography>
            <Switch
                checked={checked}
                inputProps={{ "aria-label": "two options" }}
                onChange={onChange}
            />
            <Typography>{op2}</Typography>
        </Stack>
    );
}

const downloadAllImages = async ({ order, task }) => {
    const images = [];
    const filename = `images-${order.number}-F037`;
    const taskData = task.getData();
    const sampleData = taskData.map(data => getSampleData({ data, order }));

    sampleData
        .filter(data => data.images.length > 0)
        .forEach(data =>
            data.images.map((image, index) => {
                const fileExtension = getFileExtension(image.name);
                images.push({
                    ...image,
                    name: `${data.name}${index > 0 ? `-${index + 1}` : ""}${
                        fileExtension ? "." + fileExtension : ""
                    }`,
                });
            }),
        );

    return await downloadImagesAsZip({ filename, images });
};

const getSampleData = ({ data, order }) => {
    const sample = {
        ...order.samples[data.sampleId],
    };

    const status = data.status;

    const samplingType = data.samplingType
        ? data.samplingType.name
        : data.samplingTypeOther || "";

    const climatictCondition = data.climatictCondition
        ? data.climatictCondition.name
        : "";

    const temperature = data.temperature ? data.temperature : "";

    const oilVisible =
        "oilVisible" in data ? booleanToStr(data.oilVisible) : "";

    const odor = "odor" in data ? booleanToStr(data.odor) : "";

    const damaged = "damaged" in data ? booleanToStr(data.damaged) : "";

    const cap = "cap" in data ? booleanToStr(data.cap) : "";
    const preservation =
        "preservation" in data ? booleanToStr(data.preservation) : "";

    const depletedSystemVolume = data.depletedSystemVolume
        ? data.depletedSystemVolume
        : "";

    const totalDepletedVolume = data.totalDepletedVolume
        ? data.totalDepletedVolume
        : "";

    const wellDiameter = data.wellDiameter ? data.wellDiameter.name : "";

    const NAStatic = data.NAStatic ? data.NAStatic : "";

    const depth = data.depth ? data.depth : "";

    const pumpPosition = data.pumpPosition ? data.pumpPosition : "";

    const parameters = sample.parameters ? sample.parameters.join(", ") : "";

    const stabilizationStartTime = data.startTime
        ? moment(data.startTime, moment.ISO_8601).format("HH:mm")
        : "--:--";
    const stabilizationEndTime = data.endTime
        ? moment(data.endTime, moment.ISO_8601).format("HH:mm")
        : "--:--";

    const stabilizationParameters = data.stabilizationParameters
        ? [
              ...Object.values({
                  ...(data.stabilizationParameters &&
                      data.stabilizationParameters),
              }),
          ]
              .sort((a, b) => new Date(a.time) - new Date(b.time))
              .map(p => {
                  return {
                      ...p,
                      timeText: p.time
                          ? moment(p.time, moment.ISO_8601).format("HH:mm")
                          : "--:--",
                      note: p.note ? p.note : "",
                  };
              })
        : [];

    const equipment = {
        ...(data.equipment && data.equipment),
    };

    const images = data.images
        ? [
              ...Object.values({
                  ...(data.images && data.images),
              }),
          ]
        : [];

    const note = data.note || "";

    const taskResponsible = data.taskResponsible
        ? data.taskResponsible.name
        : "";

    return {
        name: sample.name,
        status,
        samplingType,
        climatictCondition,
        temperature,
        oilVisible,
        odor,
        damaged,
        cap,
        preservation,
        depletedSystemVolume,
        totalDepletedVolume,
        wellDiameter,
        NAStatic,
        depth,
        pumpPosition,
        parameters,
        stabilizationStartTime,
        stabilizationEndTime,
        stabilizationParameters,
        images,
        note,
        taskResponsible,
        ...equipment,
        data,
    };
};

const getStartEndTime = stabilizationParameters => {
    const params = Object.values({ ...stabilizationParameters }).sort(
        (a, b) => new Date(a.time) - new Date(b.time),
    );

    const firstTime = params[0].time;
    const lastTime = params[params.length - 1].time;

    return {
        startTime: firstTime,
        endTime: lastTime,
    };
};
